<template>
    <div class="content">
        <el-button type="primary" class="el-icon-plus" @click="Add"> 新增</el-button>
        <el-card style="margin-top:15px;width:100%" class="box-card">
            <el-row :gutter="10">
              <el-col :xs="12" :sm="8" :md="6" :lg="4">
                <div class="search">
                  <label>名称</label>
                  <el-input v-model="searchForm.name" class="marg" style="width:150px"></el-input>
                </div>
              </el-col>
              <el-col :xs="12" :sm="8" :md="6" :lg="4">
                <div class="search">
                  <label>类型</label>
                  <el-select v-model="searchForm.fc_type" style="width:150px">
                      <el-option label="全部" :value="0"></el-option>
                      <el-option label="单次有效" :value="1"></el-option>
                      <el-option label="多次有效" :value="2"></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :xs="12" :sm="8" :md="6" :lg="4">
                <div class="search">
                  <el-button type="primary" plain @click="Search">查询</el-button>
                </div>
              </el-col>
            </el-row>
          </el-card> 
          
          <el-table :data='DataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="fc_name" label="名称"></el-table-column>
            <el-table-column align="center" prop="fc_type" label="优惠码类型">
                <template slot-scope="scope">
                    {{scope.row.fc_type===1?'单次有效':'多次有效'}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="dr_name" label="优惠券"></el-table-column>
            <el-table-column align="center" label="生效日期">
                <template slot-scope="scope">
                    {{FormateTime(scope.row.fc_begint_day,scope.row.fc_end_day,0)}}
                </template>
            </el-table-column>
            <el-table-column align="center" label="生效时间">
                <template slot-scope="scope">
                    {{FormateTime(scope.row.fc_begin_time,scope.row.fc_end_time,1)}}
                </template>
            </el-table-column>
            <el-table-column align="center" label="状态">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.fc_status?'success':'danger'">{{scope.row.fc_status?'正常':"停用"}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="二维码">
                <template slot-scope="scope">
                    <img src='../../../assets/image/erweima.png' style="cursor: pointer;" @click='qrcodeShow(scope.row)'/>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                   <div>
                      <el-link type="primary" @click="Edit(scope.row)">修改</el-link>
                      <el-link type="danger" @click="Del(scope.row)">删除</el-link>
                  </div>
              </template>
            </el-table-column>
          </el-table>



          <el-dialog :title="title" :visible.sync="dialogVisible" :width='screenWidth<738?"50%":(screenWidth<1200?"40%":"30%")' :append-to-body='true'>
            <el-form :model="qrForm" :rules="rules" ref="qrForm" label-width="100px" size='medium'>
                <el-form-item label="名称" prop="fc_name">
                    <el-input v-model="qrForm.fc_name"></el-input>
                </el-form-item>
                <el-form-item label="状态" prop="fc_status">
                    <el-switch style="display: block;margin-top:8px;" v-model="qrForm.fc_status" active-color="#13ce66" inactive-color="#ff4949" active-text="启用" inactive-text="停用"></el-switch>
                </el-form-item>
                <el-form-item label="优惠码类型">
                    <el-radio-group v-model="qrForm.fc_type">
                        <el-radio :label="1">单次有效</el-radio>
                        <el-radio :label="2">多次有效</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="关联优惠" prop="dr_id">
                    <el-select v-model="qrForm.fc_dr_id" style="width:100%">
                        <el-option v-for="item in DisRulesDataList" :label="item.dr_name" :value="item.dr_id" :key='item.dr_id'></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="生效日期">
                    <el-date-picker v-model="qrForm.day" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item label="生效时段">
                    <el-time-picker is-range v-model="qrForm.time" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" style="width: 100%;" value-format="HH:mm:ss"></el-time-picker>
                </el-form-item>
                <el-form-item label="二维码张数">
                    <el-radio-group v-if="qrForm.fc_type===2" v-model="num_type">
                        <el-radio :label="-1">不限制</el-radio>
                        <el-radio :label="0">自定义</el-radio>
                    </el-radio-group>
                    <el-input-number v-if='(num_type===0&&qrForm.fc_type===2)||qrForm.fc_type===1' v-model="qrForm.fc_limit_quantity" :min="1" style="margin-left: 15px;"></el-input-number>
                </el-form-item>
                <el-form-item label="有效时长">
                    <div>
                        <el-input-number v-model="qrForm.limit_len" controls-position="right" :min="1" :max="255"></el-input-number>
                        <span> 分钟</span>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
          </el-dialog>


          <el-dialog title="固定二维码" :visible.sync="qrcodeDialog" width='350px' :append-to-body='true'>
            <VueQr :text="url" :size="300" :logoScale="0.2" colorDark='green' colorLight='white'></VueQr>
            <span slot="footer" class="dialog-footer">
                <el-button @click="qrcodeDialog = false">取 消</el-button>
            </span>
          </el-dialog>

    </div>
</template>

<script>
import util from '../../../util/util'
import VueQr from 'vue-qr'
import attr from '../../../../vue.config'

export default{
    data(){
        return{
            url: '',
            
            screenWidth:0,
            searchForm:{
                name:'',
                dis_type:0
            },
            DataList:[],
            DisRulesDataList:[],


            title:'',
            dialogVisible:false,

            qrForm:{
                fc_id:0,
                fc_status:0,
                fc_name:'',
                fc_type:2,
                fc_dr_id:0,
                day:[],
                time:[],
                fc_limit_quantity:0,
                limit_len:10
            },
            num_type:-1,

            rules:{
                fc_name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
            },
            qrcodeDialog:false,
        
        }
    },
    components: {
        VueQr
    },
    computed:{
        FormateTime(){
            return function(time1,time2,type){
                var time=''
                var t1= util.FormateDate(time1,type)
                var t2=util.FormateDate(time2,type)
                time=t1+' 至 '+t2
                return time
            }
        }
    },
    created(){
        this.screenWidth = document.body.clientWidth
        util.Get('disrules/getdisrulesDataList').then(res=>{
            if(res.rpStatus===10000){
                this.DisRulesDataList=res.list
            }
        })
    },
    mounted(){
        this.GetDataList()
    },
    methods:{ 
        GetDataList(){
            util.Get('fixedcode/getfixedCodeDataList',this.searchForm).then(res=>{
                if(res.rpStatus===10000){
                    this.DataList=res.list
                }
            })
        },
        Add(){
            this.title='新增'
            this.dialogVisible=true
            this.qrForm.fc_id=0
            this.qrForm.fc_name=''
            this.qrForm.fc_type=2
            this.qrForm.fc_dr_id= this.DisRulesDataList[0].dr_id
            this.qrForm.fc_status=true
            this.qrForm.day=[]
            this.qrForm.time=[new Date(2016, 9, 10, 0, 0), new Date(2016, 9, 10, 23, 59,59)]
            this.qrForm.fc_limit_quantity=0
        },
        Search(){
            this.GetDataList()
        },
        Edit(row){
            this.dialogVisible=true
            this.title='修改'
            let data=JSON.parse(JSON.stringify(row))
            this.qrForm.fc_id=data.fc_id
            this.qrForm.fc_name=data.fc_name
            this.qrForm.fc_type=data.fc_type
            this.qrForm.fc_dr_id= data.fc_dr_id
            this.qrForm.fc_status=data.fc_status
            this.qrForm.day=[new Date(data.fc_begint_day),new Date(data.fc_end_day)]
            this.qrForm.time=[new Date(data.fc_begin_time), new Date(data.fc_end_time)]
            this.qrForm.fc_limit_quantity=data.fc_limit_quantity
        },
        Del(row){
            this.$confirm('确定删除固定码“' + row.fc_name + '”？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                util.Get('fixedcode/del?id=' + row.fc_id+"&name="+row.fc_name).then(res => {
                    if (res.rpStatus === 10000) { this.GetDataList() } else { this.$message.error(res.rpMsg) }
                })
            }).catch(e => e)
        },
        submit(){
            if(this.qrForm.fc_dr_id===0){
                this.$message.error("请选择关联的优惠券") 
                return            
            }
            
            this.$refs.qrForm.validate((valid)=>{
                if (valid) {
                    this.qrForm.fc_begint_day=''
                    this.qrForm.fc_end_day=''
                    if(this.qrForm.day&&this.qrForm.day.length>0){
                        this.qrForm.fc_begint_day=this.qrForm.day[0]
                        this.qrForm.fc_end_day=this.qrForm.day[1]
                    }
                    this.qrForm.fc_begin_time=''
                    this.qrForm.fc_end_time=''
                    if(this.qrForm.time&&this.qrForm.time.length>0){
                        this.qrForm.fc_begin_time=this.qrForm.time[0]
                        this.qrForm.fc_end_time=this.qrForm.time[1]
                    }
                    if(this.qrForm.fc_type===2&&this.num_type===-1)
                        this.qrForm.fc_limit_quantity=-1
                    util.Post('fixedcode/edit',this.qrForm).then(res=>{
                        if(res.rpStatus===10000){
                            this.dialogVisible=false
                            this.GetDataList()
                        }
                    })
                } 
            })
        },
        qrcodeShow(row){
            util.Get('fixedcode/setcode?id='+row.fc_id).then(res=>{
                if(res.rpStatus===10000){
                    this.qrcodeDialog=true
                    this.url=attr.devServer.proxy['/api'].target+'/shop/discount?code='+res.rpMsg
                }
            })
        }
    }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.search{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}

.search label{
  margin-right: 8px;
  width: 50px;
}

.el-link{
    margin-right: 10px;
    font-size: 12px;
}
</style>